<template>
    <div v-if="src">
        <div class="pdf-actions d-flex justify-center align-center">
            <v-btn
                :disabled="page <= 1"
                icon
                @click="page = page - 1"
            >
                <v-icon>keyboard_arrow_left</v-icon>
            </v-btn>
            <div>
                <input
                    type="number"
                    min="1"
                    :max="numPages"
                    :value="page"
                    :style="{ width: pageInputSize }"
                    @change="onPageManuallyChange"
                >
                <span>/ {{ numPages }}</span>
            </div>
            <v-btn
                :disabled="page >= numPages"
                icon
                @click="page = page + 1"
            >
                <v-icon>keyboard_arrow_right</v-icon>
            </v-btn>
        </div>
        <v-card class="pdf-container">
            <v-card-text>
                <div>
                    <pdf
                        :src="src"
                        :page="page"
                        @num-pages="onNumPagesUpdated"
                    />
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
    name: 'CommonPdfReader',
    components: {pdf},
    props: {
        src: {
            type: String,
            default: ""
        }
    },
    data: () => ({
        page: 1,
        numPages: 0
    }),
    computed: {
        pageInputSize () {
            let pageStringLength = this.page.toString().length - 1;
            if (pageStringLength < 1) {
                pageStringLength  = 1;
            }
            return `${20 + (10 * pageStringLength)}px`;
        }
    },
    methods: {
        onNumPagesUpdated (numPages) {
            this.numPages = numPages;
        },
        onPageManuallyChange (event) {
            const newPage = parseInt(event.target.value);
            if (newPage >= 1 && newPage <= this.numPages) {
                this.page = newPage;
            }
        }
    }
}
</script>

<style scoped>
.pdf-container {
  max-width: 1000px;
  margin: 0 auto;
}

.pdf-actions {
  background-color: #eee;
  border-radius: 20px;
  width: max-content;
  margin: 0 auto 20px;
  position: sticky;
  top: 80px;
  z-index: 2;
  box-shadow: 0 0 10px 10px #fafafa;
}
</style>

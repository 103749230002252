<template>
    <v-container class="container">
        <v-layout
            align-space-start
            justify-start
            row
            fill-height
        >
            <v-flex>
                <div class="side-panel">
                    <div class="side-panel-content">
                        <div class="side-panel-actions">
                            <edition-action-panel
                                @save="saveDocument"
                                @cancel="goBack"
                            />
                        </div>
                    </div>
                </div>
            </v-flex>
            <v-flex class="structure-editor-content pa-4">
                <v-card>
                    <v-card-title>
                        <h3>{{ $t('document.structureEditorPdf.form.title') }}</h3>
                    </v-card-title>
                    <v-divider />
                    <v-card-text class="pa-4">
                        <input
                            id="pdf-file"
                            type="file"
                            class="d-none"
                            accept="application/pdf"
                        >
                        <p>
                            {{ $t('document.structureEditorPdf.form.description') }}
                        </p>
                        <div
                            class="d-flex align-center"
                            style="width: max-content"
                        >
                            <button id="pdf-file-btn">
                                <span class="d-flex align-center">
                                    {{ $t('document.structureEditorPdf.form.fileInput') }}
                                    <v-chip v-if="pdfFile">
                                        {{ pdfFile.name }}
                                    </v-chip>
                                    <v-icon>attach_file</v-icon>
                                </span>
                            </button>
                        </div>
                    </v-card-text>
                </v-card>

                <CommonPdfReader
                    :src="pdfFileSrc"
                    class="mt-4"
                />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";
import EditionActionPanel from "@/components/shared/EditionActionPanel.vue";
import EventBus from "@/utils/event-bus";
import ConstantEvent from "@/shared/constant/constantEvent";
import ConstantNotification from "@/shared/constant/constantNotification";
import CommonPdfReader from "@/components/shared/CommonPdfReader.vue";
import axios from "axios";

export default {
    name: 'StructureEditorPdf',
    components: {
        EditionActionPanel,
        CommonPdfReader
    },
    data: () => ({
        pdfFile: null,
        pdfFileSrc: null
    }),
    computed: {
        ...mapState({
            idDocument: state => state.dataSheetStore.metaDocument.idDocument
        })
    },
    created() {
        this.loadDataSheetById(this.$route.params.id);
        if (!!this.idDocument) {
            this.loadDocumentContent({id: this.idDocument, toLock: true})
                .catch(() => this.$router.back());
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.bindEventClickOnFileBtn();
            this.bindEventOnFileSelected();
        });
    },
    methods: {
        ...mapActions({
            loadDocumentContent: 'documentContent/loadDocumentContent',
            loadDataSheetById: 'dataSheetStore/loadDataSheetById',
        }),
        saveDocument() {
            if (!this.pdfFile) {
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: this.$t('document.structureEditorPdf.form.errorFileRequired'),
                    type: ConstantNotification.ERROR
                });
                return;
            }

            const formData = new FormData();
            formData.append('documentId', this.idDocument);
            formData.append('file', this.pdfFile);

            axios
                .post("/content/save-pdf", formData)
                .then((e) => {
                    console.log(e)
                    if (e && e.status === 200) {
                        EventBus.$emit(ConstantEvent.ADD_NOTIFICATION,  {
                            type: ConstantNotification.SUCCEED,
                            message: 'document.notification.content.save.succeed',
                        });
                        this.goBack()
                    } else {
                        EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                            message: this.$t('document.structureEditorPdf.form.errorSave'),
                            type: ConstantNotification.ERROR
                        });
                    }
                });
        },
        goBack() {
            this.$router.go(-1);
        },
        bindEventClickOnFileBtn () {
            const pdfFileBtn = document.getElementById('pdf-file-btn');
            const pdfFileInput = document.getElementById('pdf-file');
            pdfFileBtn.addEventListener('click', () => pdfFileInput.click());
        },
        bindEventOnFileSelected () {
            const pdfFileInput = document.getElementById('pdf-file');
            pdfFileInput.addEventListener('change', (event) => {
                const file = event.target.files[0];
                if (file && file.type === 'application/pdf') {
                    this.pdfFile = file;
                    this.pdfFileSrc = URL.createObjectURL(this.pdfFile);
                } else {
                    this.pdfFile = null;
                    this.pdfFileSrc = null;

                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                        message: this.$t('document.structureEditorPdf.form.errorFileType'),
                        type: ConstantNotification.ERROR
                    });
                }
            });
        }
    },
}
</script>


<style scoped>
.container {
  margin: 0;
  padding: 0;
  min-width: 100% !important;
}

.side-panel {
  background-color: #355B7F;
  width: 300px;
  height: calc(100vh - 40px);
}

.side-panel .side-panel-content {
  height: calc(100vh - 280px);
  overflow-y: auto;
  background-color: #355B7F;
  position: fixed;
  width: 300px;
}

.side-panel .side-panel-content .side-panel-actions {
  border-top: solid 1px white;
  position: fixed;
  bottom: 0;
  width: 300px;
  height: 240px;
  background-color: #355B7F;
}


.structure-editor-content {
  width: 100%;
}

.structure-editor-content #pdf-file-btn {
  background-color: #eee;
  border-radius: 30px;
  padding: 6px 20px;
  min-width: 200px;
  border: 1px solid #aaa;
}
</style>

<template>
    <div class="mt-4">
        <CommonPdfReader :src="pdfFileSrc" />
    </div>
</template>

<script>
import {mapActions} from "vuex";
import CommonPdfReader from "@/components/shared/CommonPdfReader.vue";

export default {
    name: 'DocumentPdfReader',
    components: {CommonPdfReader},
    props: {
        documentId: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        pdfFile: null,
        pdfFileSrc: null
    }),
    mounted() {
        this.loadDocumentPdf(this.documentId).then((pdfBlob) => {
            this.pdfFile = pdfBlob;
            this.pdfFileSrc = URL.createObjectURL(pdfBlob);
            console.log(pdfBlob);
        });
    },
    methods: {
        ...mapActions({
            loadDocumentPdf: 'documentContent/loadDocumentPdf',
        }),
    }
}
</script>

import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/Login'
import Main from './components/Main'
import Administration from './components/administration/Main'
import AdministrationOverview from './components/administration/Overview'
import Book from './components/administration/publication/book/Main'
import BookSearch from './components/administration/publication/book/Search'
import BookManagement from './components/administration/publication/book/Management'
import Dashboard from './components/dashboard/Main'
import LastModifyDocument from './components/dashboard/document/LastModifyDocument'
import DocumentJurisdiction from './components/administration/jurisdiction/DocumentJurisdiction'
import AddJurisdiction from './components/administration/jurisdiction/AddJurisdiction'
import Keyword from './components/administration/keyword/Main'
import KeywordSearch from './components/administration/keyword/Search'
import KeywordManagement from './components/administration/keyword/Management'
import Workspace from './components/administration/workspace/Main'
import WorkspaceSearch from './components/administration/workspace/Search'
import WorkspaceManagement from './components/administration/workspace/Management'
import GuidelineSource from './components/administration/guidelinesource/Main'
import GuidelineSourceSearch from './components/administration/guidelinesource/Search'
import GuidelineSourceManagement from './components/administration/guidelinesource/Management'
import Document from './components/document/Main'
import DocumentSearch from './components/document/search/DocumentSearch'
import StructureEditor from './components/document/structure/StructureEditor'
import StructureEditorPdf from './components/document/structure/StructureEditorPdf'
import ManageModificator from './components/document/modificator/ManageModificator'
import DatasheetManagement from './components/document/dataSheet/DatasheetManagement'
import OverviewDocument from './components/document/overview/OverviewDocument'
import DocumentConsolidation from './components/document/consolidation/DocumentConsolidation'
import DocumentModificator from './components/document/consolidation/DocumentModificator'
import DocumentDisplay from './components/document/details/DocumentDisplay'
import HyperLinkManagement from './components/document/link/HyperLinkManagement'
import Pao from './components/pao/Main'
import PaoDisplay from './components/pao/details/PaoDisplay'
import PaoManagement from './components/pao/PaoManagement'
import PaoLinkManagement from './components/pao/link/PaoLinkManagement'
import PaoSearch from './components/pao/search/PaoSearch'
import Magazine from './components/administration/publication/magazine/Main'
import User from './components/administration/user/Main'
import UserSearch from './components/administration/user/Search'
import UserManagement from './components/administration/user/Management'
import MagazineManagement
    from './components/administration/publication/magazine/MagazineManagement'
import MagazineSearch
    from './components/administration/publication/magazine/MagazineSearch'
import SummaryLinkManagement from './components/document/paolink/SummarylinkManagement'
import Partner from './components/administration/partner/Main'
import PartnerSearch from './components/administration/partner/Search'
import PartnerManagement from './components/administration/partner/Management'
import Domain from './components/administration/domain/Main'
import DomainSearch from './components/administration/domain/Search'
import DomainManagement from './components/administration/domain/Management'
import Country from './components/administration/country/Main'
import CountrySearch from './components/administration/country/Search'
import CountryManagement from './components/administration/country/Management'
import Nature from './components/administration/nature/Main'
import NatureSearch from './components/administration/nature/Search'
import NatureManagement from './components/administration/nature/Management'
import Author from './components/administration/author/Main';
import AuthorSearch from './components/administration/author/Search';
import AuthorManagement from './components/administration/author/Management'


/**
 *
 * Application router.
 * @author Sébastien DE SANTIS, SFEIR Benelux
 * @version 1.0
 * @since 12/09/2018
 *
 */
Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '*',
            redirect: '/dashboard'
        },
        {
            name: 'login',
            path: '/',
            component: Login
        },
        {
            name: 'main',
            path: '/home',
            component: Main,
            meta: {navTitle: "Home"},
            children: [
                {
                    name: 'document',
                    path: '/document',
                    component: Document,
                    meta: {navTitle: " Document "},
                    children: [
                        {
                            name: 'search',
                            path: 'search',
                            component: DocumentSearch,
                            alias: '',
                            meta: {navTitle: " Search "}
                        },
                        {
                            path: 'editor/:reference/:id',
                            component: StructureEditor,
                            meta: {navTitle: " "},
                            beforeEnter: (to, from, next) => {
                                if (!!from.params.title) {
                                    to.params.title = from.params.title;
                                    to.params.popup = true;
                                }
                                next();
                            }
                        },
                        {
                            path: 'editor-pdf/:reference/:id',
                            component: StructureEditorPdf,
                            meta: {navTitle: " "},
                            beforeEnter: (to, from, next) => {
                                if (!!from.params.title) {
                                    to.params.title = from.params.title;
                                    to.params.popup = true;
                                }
                                next();
                            }
                        },
                        {
                            path: 'manageConsolidation/:id',
                            name: 'manageConsolidation',
                            component: ManageModificator,
                            meta: {navTitle: " Manage Modificator "},
                            beforeEnter: (to, from, next) => {
                                if (!!from.params.title) {
                                    to.params.title = from.params.title;
                                }
                                next();
                            }
                        },
                        {
                            path: 'consolidation/:reference/:id',
                            component: DocumentConsolidation,
                            meta: {navTitle: " Consolidation"},
                            beforeEnter: (to, from, next) => {
                                if (!!from.params.title) {
                                    to.params.title = from.params.title;
                                }
                                next();
                            }
                        },
                        {
                            path: 'cuDocument',
                            name: 'DatasheetAdd',
                            component: DatasheetManagement,
                            meta: {navTitle: " "},
                            beforeEnter: (to, from, next) => {
                                if (!!from.params.title) {
                                    to.params.title = from.params.title;
                                }
                                next();
                            }
                        },
                        {
                            path: 'cuDocument/:id',
                            name: 'DatasheetEdit',
                            component: DatasheetManagement,
                            meta: {navTitle: " "},
                            beforeEnter: (to, from, next) => {
                                if (!!from.params.title) {
                                    to.params.title = from.params.title;
                                    to.params.popup = true;
                                }
                                next();
                            }
                        },
                        {
                            path: 'overview',
                            component: OverviewDocument,
                            meta: {navTitle: " "},
                            beforeEnter: (to, from, next) => {
                                if (!!from.params.title) {
                                    to.params.title = from.params.title;
                                }
                                next();
                            }
                        },
                        {
                            path: 'hyperLink/:id',
                            component: HyperLinkManagement,
                            meta: {navTitle: " hyperlink management "},
                            beforeEnter: (to, from, next) => {
                                if (!!from.params.saveTitle) {
                                    to.params.title = from.params.saveTitle;
                                } else if (!!from.params.title) {
                                    to.params.title = from.params.title;
                                }
                                to.params.popup = true;
                                next();
                            }
                        },
                        {
                            path: 'paolink',
                            name: 'paolink',
                            component: SummaryLinkManagement,
                            meta: {navTitle: " paolink management "},
                            beforeEnter: (to, from, next) => {
                                if (!!from.params.saveTitle) {
                                    to.params.title = from.params.saveTitle;
                                } else if (!!from.params.title) {
                                    to.params.title = from.params.title;
                                }
                                next();
                            }
                        },
                        {
                            path: ':idDocument/pao',
                            component: PaoLinkManagement,
                            meta: {navTitle: " Pao management "},
                            beforeEnter: (to, from, next) => {
                                if (!!from.params.saveTitle) {
                                    to.params.title = from.params.saveTitle;
                                } else if (!!from.params.title) {
                                    to.params.title = from.params.title;
                                }
                                to.params.popup = true;
                                next();
                            }
                        },
                        {
                            name: 'details',
                            path: 'documentDisplay/:reference',
                            component: DocumentDisplay,
                            meta: {navTitle: " details "},
                            beforeEnter: (to, from, next) => {
                                if (!!to.params.title) {
                                    from.params.title = to.params.title;
                                } else {
                                    to.params.title = from.params.title;
                                }
                                next();
                            }
                        },
                        {
                            name: 'versionDetails',
                            path: 'documentDisplay/:reference/:version',
                            component: DocumentDisplay,
                            meta: {navTitle: " details "},
                            beforeEnter: (to, from, next) => {
                                if (!!to.params.title) {
                                    from.params.title = to.params.title;
                                } else {
                                    to.params.title = from.params.title;
                                }
                                next();
                            }
                        }
                    ]
                },
                {
                    name: 'pao',
                    path: '/pao',
                    component: Pao,
                    meta: {navTitle: " Pao "},
                    children: [
                        {
                            name: 'paoSearch',
                            path: 'search',
                            component: PaoSearch,
                            alias: '',
                            meta: {navTitle: " Search "}
                        },
                        {
                            name: 'paoDetails',
                            path: 'paoDisplay/:id',
                            component: PaoDisplay,
                            meta: {navTitle: " details "},
                            beforeEnter: (to, from, next) => {
                                if (!!to.params.title) {
                                    from.params.title = to.params.title;
                                } else {
                                    to.params.title = from.params.title;
                                }
                                next();
                            }
                        },
                        {
                            path: 'manage',
                            name: "PaoAdd",
                            component: PaoManagement,
                            meta: {navTitle: "Add"}
                        },
                        {
                            path: 'manage/:id',
                            name: "PaoEdit",
                            component: PaoManagement,
                            meta: {navTitle: "Update"}
                        }
                    ]
                },
                {
                    path: '/administration',
                    component: Administration,
                    meta: {navTitle: "Administration "},
                    children: [
                        {
                            path: '',
                            component: AdministrationOverview,
                            meta: {navTitle: " Overview "}
                        },
                        {
                            path: 'documentjurisdiction',
                            component: DocumentJurisdiction,
                            meta: {navTitle: " Jurisdiction "}
                        },
                        {
                            path: 'add',
                            component: AddJurisdiction,
                            meta: {navTitle: " Add Jurisdiction "}
                        },
                        {
                            path: 'add/:id',
                            component: AddJurisdiction,
                            meta: {navTitle: " Add Jurisdiction "}
                        },
                        {
                            path: 'keyword',
                            component: Keyword,
                            meta: {navTitle: " Keyword "},
                            children: [
                                {
                                    name: "KeywordSearch",
                                    path: '',
                                    component: KeywordSearch,
                                },
                                {
                                    name: "KeywordEdit",
                                    path: 'manage/:id',
                                    component: KeywordManagement
                                },
                                {
                                    name: "KeywordAdd",
                                    path: 'manage',
                                    component: KeywordManagement
                                }
                            ]
                        },
                        {
                            path: 'workspace',
                            component: Workspace,
                            meta: {navTitle: " Workspace "},
                            children: [
                                {
                                    name: 'WorkspaceSearch',
                                    path: '',
                                    component: WorkspaceSearch
                                },
                                {
                                    name: 'WorkspaceAdd',
                                    path: 'manage',
                                    component: WorkspaceManagement
                                },
                                {
                                    name: 'WorkspaceEdit',
                                    path: 'manage/:id',
                                    component: WorkspaceManagement
                                }
                            ]
                        },
                        {
                            path: 'guidelinesource',
                            component: GuidelineSource,
                            meta: {navTitle: " Guideline Source "},
                            children: [
                                {
                                    name: 'GuidelineSourceSearch',
                                    path: '',
                                    component: GuidelineSourceSearch
                                },
                                {
                                    name: "GuidelineSourceAdd",
                                    path: 'manage',
                                    component: GuidelineSourceManagement
                                },
                                {
                                    name: "GuidelineSourceEdit",
                                    path: 'manage/:id',
                                    component: GuidelineSourceManagement
                                }
                            ]
                        },
                        {
                            path: 'country',
                            component: Country,
                            meta: {navTitle: " Country"},
                            children: [
                                {
                                    name: 'CountrySearch',
                                    path: '',
                                    component: CountrySearch
                                },
                                {
                                    name: 'CountryAdd',
                                    path: 'manage',
                                    component: CountryManagement
                                },
                                {
                                    name: 'CountryEdit',
                                    path: 'manage/:id',
                                    component: CountryManagement
                                }
                            ]
                        },
                        {
                            path: 'users',
                            component: User,
                            meta: {navTitle: " User"},
                            children: [
                                {
                                    name: 'UserSearch',
                                    path: '',
                                    component: UserSearch
                                },
                                {
                                    name: 'UserAdd',
                                    path: 'manage',
                                    component: UserManagement
                                },
                                {
                                    name: 'UserEdit',
                                    path: 'manage/:id',
                                    component: UserManagement
                                }
                            ]
                        },
                        {
                            path: 'partner',
                            component: Partner,
                            meta: {navTitle: " Partner "},
                            children: [
                                {
                                    name: 'PartnerSearch',
                                    path: '',
                                    component: PartnerSearch
                                },
                                {
                                    name: 'PartnerAdd',
                                    path: 'manage',
                                    component: PartnerManagement
                                },
                                {
                                    name: 'PartnerEdit',
                                    path: 'manage/:id',
                                    component: PartnerManagement
                                }
                            ]
                        },
                        {
                            path: 'nature',
                            component: Nature,
                            meta: {navTitle: " Nature "},
                            children: [
                                {
                                    name: 'NatureSearch',
                                    path: '',
                                    component: NatureSearch
                                },
                                {
                                    name: 'NatureAdd',
                                    path: 'manage',
                                    component: NatureManagement
                                },
                                {
                                    name: 'NatureEdit',
                                    path: 'manage/:id',
                                    component: NatureManagement
                                }
                            ]
                        },
                        {
                            path: 'domain',
                            component: Domain,
                            meta: {navTitle: " Domain "},
                            children: [
                                {
                                    name: 'DomainSearch',
                                    path: '',
                                    component: DomainSearch
                                },
                                {
                                    name: 'DomainAdd',
                                    path: 'manage',
                                    component: DomainManagement
                                },
                                {
                                    name: 'DomainEdit',
                                    path: 'manage/:id',
                                    component: DomainManagement
                                }
                            ]
                        },
                        {
                            path: 'book',
                            component: Book,
                            meta: {navTitle: " Book "},
                            children: [
                                {
                                    name: 'BookSearch',
                                    path: '',
                                    component: BookSearch
                                },
                                {
                                    name: 'BookAdd',
                                    path: 'manage',
                                    component: BookManagement
                                },
                                {
                                    name: "BookEdit",
                                    path: 'manage/:id',
                                    component: BookManagement
                                }
                            ]
                        },
                        {
                            path: 'magazine',
                            component: Magazine,
                            meta: {navTitle: " Magazine "},
                            children: [
                                {
                                    name: 'MagazineSearch',
                                    path: '',
                                    component: MagazineSearch
                                },
                                {
                                    name: 'MagazineAdd',
                                    path: 'manage',
                                    component: MagazineManagement
                                },
                                {
                                    name: 'MagazineEdit',
                                    path: 'manage/:id',
                                    component: MagazineManagement
                                }
                            ]
                        },
                        {
                            path: 'author',
                            component: Author,
                            meta: {navTitle: " Author " },
                            children: [
                                {
                                    name: 'AuthorSearch',
                                    path: '',
                                    component: AuthorSearch
                                },
                                {
                                    name: 'AuthorAdd',
                                    path: 'manage',
                                    component: AuthorManagement
                                },
                                {
                                    name: 'AuthorEdit',
                                    path: 'manage/:id',
                                    component: AuthorManagement
                                }
                            ]
                        }
                    ]
                },
                {
                    name: 'dashboard',
                    path: '/dashboard',
                    component: Dashboard,
                    meta: {navTitle: "Home"},
                    children: [{
                        path: '10documentModify',
                        component: LastModifyDocument,
                        alias: '',
                        meta: {navTitle: ""}

                    }]
                }
            ]
        },
        {
            name: 'documentModificator',
            path: '/:documentId/documentModificator/:modificatorId',
            component: DocumentModificator
        }
    ]
});


import store from './store';

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token'); //gets a string

    if (to.name && to.name === 'login') {
        //user is trying to access login but if he is already logged, should redirect to dashboard
        if (token !== 'null') {
            //user is logged in
            next('/dashboard');
        } else {
            next();
        }
    } else {
        //trying to access another application route that requires authentication
        if (token === 'null' && !(from.name && from.name === 'login')) {
            //user is not logged in
            next({name: 'login'});
        } else {
            next();
        }
    }
});

export default router;
